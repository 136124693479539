import { forEach as _forEach, uniq as _uniq } from 'lodash'
import Vue from 'vue'

import API from '_api'

const actions = {
    async fetchMeters ({ commit, state }, { personalAccountNumber, companyCode }) {
        if (state.status === 'idle') {
            commit('fetchMetersRequest')

            try {
                const meters = await API.meter.getMeters({ account_number: personalAccountNumber, company_id: companyCode })

                const groups = []
                const tariffs = []

                let groupNames = []

                _forEach(meters, (meter) => {
                    groupNames.push(meter?.customName || meter.typeName)

                    // Тарифы
                    for (let tariffNumber = 1; tariffNumber <= meter.tariffsCount; tariffNumber++) {
                        const tariff = {}

                        tariff.id = `${meter.extId}___${tariffNumber}`
                        tariff.number = tariffNumber

                        if (meter.tariffsCount > 1) {
                            tariff.name = `T${tariff.number}`
                        } else {
                            tariff.name = meter.typeName
                        }

                        tariff.meterExtId = meter.extId

                        // Показания
                        const tariffIndex = tariff.number - 1
                        if (meter.lastMeterReadings[tariffIndex]) {
                            tariff.lastMeterReading = meter.lastMeterReadings[tariffIndex]
                        } else {
                            tariff.lastMeterReading = 0

                            meter.lastMeterReadings.push(tariff.lastMeterReading)
                        }

                        if (meter.middleMeterConsumes[tariffIndex]) {
                            tariff.middleMeterConsume = meter.middleMeterConsumes[tariffIndex]
                        } else {
                            tariff.middleMeterConsume = 0

                            meter.middleMeterConsumes.push(tariff.middleMeterConsume)
                        }

                        tariffs.push(tariff)
                    }
                })

                if (meters?.length) {
                    groupNames = _uniq(groupNames)

                    _forEach(groupNames, (groupName, index) => {
                        const group = {}

                        group.id = index + 1
                        group.name = groupName

                        groups.push(group)
                    })

                    _forEach(meters, (meter) => {
                        _forEach(groups, (group) => {
                            const meterName = meter?.customName || meter.typeName

                            if (group.name === meterName) {
                                meter.groupId = group.id
                            }
                        })
                    })
                }

                commit('fetchMetersSucceeded', { meters, groups, tariffs })
                await Vue.nextTick()
                commit('fetchMetersCompleted')
            } catch (errors) {
                commit('fetchMetersFailed', errors)
                await Vue.nextTick()
                commit('fetchMetersCompleted')
                return Promise.reject(errors)
            }
        }
    },

    async sendMeterReadings ({ commit, state }, { meterExtId, data }) {
        if (state.status === 'idle') {
            commit('sendMeterReadingsRequest')

            try {
                await API.meter.sendMeterReadings({
                    meterExtId,
                    data,
                })

                commit('sendMeterReadingsSucceeded')
                await Vue.nextTick()
                commit('sendMeterReadingsCompleted')
            } catch (errors) {
                commit('sendMeterReadingsFailed', errors)
                await Vue.nextTick()
                commit('sendMeterReadingsCompleted')
                return Promise.reject(errors)
            }
        }
    },
}

export default actions
