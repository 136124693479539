import { forEach as _forEach } from 'lodash'
import Vue from 'vue'

const mutations = {
    setCKC (state, ckc) {
        state.ckc = ckc
    },

    clearMeters (state) {
        state.status = 'idle'
        state.errors = []

        state.meters = {}
        state.groups = {}
        state.tariffs = {}
    },

    fetchMetersRequest (state) {
        state.status = 'fetching'
    },
    fetchMetersSucceeded (state, { meters, groups, tariffs }) {
        state.status = 'fetched'
        state.errors = []

        _forEach(meters, (meter) => {
            Vue.set(state.meters, meter.extId, meter)
        })
        _forEach(groups, (group) => {
            Vue.set(state.groups, group.id, group)
        })
        _forEach(tariffs, (tariff) => {
            Vue.set(state.tariffs, tariff.id, tariff)
        })
    },
    fetchMetersFailed (state, errors) {
        state.status = 'failed'
        state.errors = errors
    },
    fetchMetersCompleted (state) {
        state.status = 'idle'
    },

    sendMeterReadingsRequest (state) {
        state.status = 'sending'
    },
    sendMeterReadingsSucceeded (state) {
        state.status = 'sent'
        state.errors = []
    },
    sendMeterReadingsFailed (state, errors) {
        state.status = 'failed'
        state.errors = errors
    },
    sendMeterReadingsCompleted (state) {
        state.status = 'idle'
    },

}

export default mutations
