import { map as _map } from 'lodash'

import { request } from '_api/client'

import Meter from '_entities/meter/Meter'

export const getMeters = async (params) => {
    const response = await request('GET', 'meter/readings/meters/', params)

    const { results } = response

    return _map(results, (result) => new Meter(result))
}

export const sendMeterReadings = async ({ meterExtId, data }) => {
    return await request('POST', `meter/${meterExtId}/metering/`, data)
}
