import Entity from '_entities/base/BaseEntity'

export const UpdateMeteringSchema = {
    name: 'UpdateMetering',
    properties: {
        canUpdate: { type: 'bool' },
        message: { type: 'string' },
    },
}

class UpdateMetering extends Entity {
    get schema () {
        return UpdateMeteringSchema
    }

    get dataMap () {
        return {
            can_update: 'canUpdate',
        }
    }

    static fieldLabels = {
        canUpdate: 'Возможность подачи',
        message: 'Сообщение',
    }
}

export default UpdateMetering
