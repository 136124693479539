import mutations from './mutations'
import actions from './actions'

const state = {
    ckc: null,
    errors: [],
    groups: {},
    meters: {},
    status: 'idle', // Status: idle | fetching | fetched | sending | sent | failed
    tariffs: {},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
