import Entity from '_entities/base/BaseEntity'

export const CompanySchema = {
    name: 'Company',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class Company extends Entity {
    get schema () {
        return CompanySchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Наименование',
    }
}

export default Company
