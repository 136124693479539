import Vue from 'vue'
import Vuex from 'vuex'

import companies from '_features/companies/store/'
import meters from '_features/meters/store/'
import notifications from '_features/notifications/store'
import personalAccount from '_features/personalAccount/store/'
import softwareUpdate from '_features/softwareUpdate/store'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        companies,
        meters,
        notifications,
        personalAccount,
        softwareUpdate,
    },
})
