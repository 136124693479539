import Entity from '_entities/base/BaseEntity'

export const PremiseSchema = {
    name: 'Premise',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        number: { type: 'string' },
    },
}

class Premise extends Entity {
    get schema () {
        return PremiseSchema
    }

    static fieldLabels = {
        id: 'ID',
        nubmer: 'Номер',
    }
}

export default Premise
