import mutations from './mutations'

const state = {
    personalAccountNumber: null,
}

export default {
    namespaced: true,
    state,
    mutations,
}
