import { map as _map } from 'lodash'

import { request } from '_api/client'

import PersonalAccount from '_entities/personalAccount/PersonalAccount'

export const getPersonalAccounts = async (params) => {
    const response = await request('GET', 'account/', params)

    const { results } = response

    return _map(results, (result) => new PersonalAccount(result))
}
