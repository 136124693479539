import Vue from 'vue'
import VueRouter from 'vue-router'

const MeterReadingsScreen = () => import('_screens/MeterReadingsScreen')
const SignInScreen = () => import('_screens/SignInScreen')
const IncorrectLinkScreen = () => import('_screens/IncorrectLinkScreen')
const SendDoneScreen = () => import('_screens/SendDoneScreen')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'SignInScreen',
        component: SignInScreen,
    },
    {
        path: '/MeterReadings',
        name: 'MeterReadings',
        component: MeterReadingsScreen,
    },
    {
        path: '/IncorrectLink',
        name: 'IncorrectLink',
        component: IncorrectLinkScreen,
    },
    {
        path: '/SendDone',
        name: 'SendDone',
        component: SendDoneScreen,
    },
]

const router = new VueRouter({
    routes,
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
