import { map as _map } from 'lodash'

import { request } from '_api/client'

import Building from '_entities/building/Building'

export const getBuildings = async (params) => {
    const response = await request('GET', 'building/', params)

    const { results } = response

    return _map(results, (building) => new Building(building))
}
