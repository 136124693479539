import * as auth from './auth'
import * as building from './building'
import * as meter from './meter'
import * as personalAccount from './personalAccount'
import * as premise from './premise'

const API = {
    auth,
    building,
    meter,
    personalAccount,
    premise,
}

export default API
