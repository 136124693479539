<template>
    <v-app>
        <template v-if="isInitializing">
            <v-container class="primary fill-height" fluid>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-progress-circular indeterminate color="white" />
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-else>
            <UISystemBar />
            <v-main>
                <router-view />
            </v-main>

            <UISnackbar />
        </template>
    </v-app>
</template>

<script>
    import API from '_api'

    import UISnackbar from '_ui/components/UISnackbar'
    import UISystemBar from '_ui/components/UISystemBar'

    export default {
        name: 'App',

        components: {
            UISnackbar,
            UISystemBar,
        },

        data: () => ({
            isInitializing: true,
        }),

        created () {
            this.bootstrap()
        },

        methods: {
            async bootstrap () {
                this.listenSoftwareUpdate()

                this.urlCheck()

                await this.getToken()

                await new Promise((resolve) => {
                    setTimeout(() => resolve(), 500)
                })

                this.isInitializing = false
            },

            async getToken () {
                await API.auth.getToken()
            },

            listenSoftwareUpdate () {
                if (process.env.NODE_ENV === 'production') {
                    document.addEventListener('serviceWorkerUpdated', this.onServiceWorkerUpdated, { once: true })

                    navigator.serviceWorker.addEventListener('controllerchange', () => {
                        if (this.$store.state.softwareUpdate.isUpdating) {
                            return
                        }

                        this.$store.commit('softwareUpdate/setUpdating', true)

                        window.location.reload()
                    })
                }
            },

            onServiceWorkerUpdated (event) {
                // Обновление найдено
                this.$store.dispatch('softwareUpdate/setServiceWorkerRegistration', event.detail)

                const serviceWorkerRegistration = this.$store.state.softwareUpdate.serviceWorkerRegistration

                this.$root.pushNotification({
                    text: 'Новая версия ПО',
                    action: {
                        text: 'Обновить',
                        click: () => {
                            this.$store.dispatch('softwareUpdate/update', {
                                serviceWorkerRegistration,
                            })
                        },
                    },
                })
            },

            urlCheck () {
                let href = window.location.href
                href = href.replace('#/', '')

                const urlParams = new URL(href)
                const companyId = urlParams.searchParams.get('companyId')
                const ckc = urlParams.searchParams.get('ckc')

                if (ckc || companyId) {
                    if (ckc) {
                        this.$store.commit('meters/setCKC', ckc)
                    }
                    if (companyId) {
                        this.$store.commit('companies/selectCompany', companyId)
                    }
                } else {
                    this.$router.push({ name: 'IncorrectLink' })
                }
            },
        },
    }
</script>
