import Entity from '_entities/base/BaseEntity'

import Unit from '_entities/unit/Unit'
import UpdateMetering from '_entities/meter/UpdateMetering'

export const MeterSchema = {
    name: 'Meter',
    primaryKey: 'id',
    properties: {
        canUpdateMetering: { type: 'UpdateMetering', entity: UpdateMetering },
        customName: { type: 'string' },
        extId: { type: 'string' },
        lastMeterReadings: { type: 'int[]', default: [] },
        lastMeterReadingsDate: { type: 'int' },
        meterType: { type: 'string' },
        middleMeterConsumes: { type: 'float[]', default: [] },
        number: { type: 'string' },
        tariffsCount: { type: 'int' },
        unit: { type: 'Unit', entity: Unit },
    },
}

class Meter extends Entity {
    get schema () {
        return MeterSchema
    }

    get dataMap () {
        return {
            can_update_metering: 'canUpdateMetering',
            custom_name: 'customName',
            ext_id: 'extId',
            last_meter_readings: 'lastMeterReadings',
            last_meter_readings_date: 'lastMeterReadingsDate',
            meter_type: 'meterType',
            middle_meter_consumes: 'middleMeterConsumes',
            tariffs_count: 'tariffsCount',
        }
    }

    static fieldLabels = {
        customName: 'Пользовательское наименование',
        extId: 'Внешнее id',
        lastMeterReadings: 'Последние переданные значения',
        lastMeterReadingsDate: 'Дата последней подачи',
        meterType: 'Тип счтчика',
        middleMeterConsumes: 'Средние показания',
        number: 'Номер счетчика',
        tariffsCount: 'Количество счетчиков тарифа',
        unit: 'Единицы изменения',
    }

    get typeName () {
        switch (this.meterType) {
            case 'hot':
                return 'Горячая вода'
            case 'cold':
                return 'Холодная вода'
            case 'electricity':
                return 'Электроэнергия'
            case 'heat':
                return 'Отопление'
            default:
                return this.meterType
        }
    }
}

export default Meter
