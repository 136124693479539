import { map as _map } from 'lodash'

import { request } from '_api/client'

import Premise from '_entities/premise/Premise'

export const getPremises = async (params) => {
    const response = await request('GET', 'premise/', params)

    const { results } = response

    return _map(results, (item) => new Premise(item))
}
