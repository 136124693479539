import mutations from './mutations'

const state = {
    companies: [
        {
            id: '84ee1cf7-8725-4a85-b5db-f0f250562163',
            name: 'ООО "УК "ГОРИЗОНТ"',
        },
        {
            id: '8ed0852d-514d-4d70-86b9-8132842f5954',
            name: 'УК МОЯ СТРАНА ООО',
        },
    ],
    errors: [],
    selectedCompany: null,
    status: 'idle',
}

export default {
    namespaced: true,
    state,
    mutations,
}
